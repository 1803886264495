import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

PageTitle.defaultProps = {
  title: null,
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;

const Urls = {
  front: {
    base: '/',
    login: {
      base: '/login',
      object: '/login/:key',
    },
    logout: '/logout',
    health: '/health',
  },
  home: {
    base: '/:shopKey/home',
  },
  members: {
    base: '/:shopKey/members',
    import: {
      base: '/:shopKey/members/import',
      new: '/:shopKey/members/import/new',
    },
    object: '/:shopKey/members/:id',
  },
  segments: {
    base: '/:shopKey/segments',
    new: '/:shopKey/segments/new',
    object: '/:shopKey/segments/:id',
  },
  integrations: {
    base: '/:shopKey/integrations',
  },
  settings: {
    base: '/:shopKey/settings',
    general: '/:shopKey/settings/general',
    account: '/:shopKey/settings/account',
    team: '/:shopKey/settings/team',
    plan: '/:shopKey/settings/plan',
  },
  welcome: {
    base: '/:shopKey/welcome',
    object: '/:shopKey/welcome/:id',
  },
  flag: {
    trial_over: '/flag/trial-over',
    delinquency: '/flag/delinquency',
  },
  select: {
    card: '/card/select',
  },
  analytics: {
    base: '/:shopKey/analytics',
  },
  rules: {
    base: '/:shopKey/rules',
    add: '/:shopKey/rules/add',
    object: '/:shopKey/rules/:uid',
  },
  referrals: {
    base: '/:shopKey/referrals',
  },
  transactions: {
    base: '/:shopKey/transactions',
  },
  tiers: {
    base: '/:shopKey/tiers',
    object: '/:shopKey/tiers/:id',
  },
};

export default Urls;
